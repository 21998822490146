import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.scss';

const MechanismSection: React.FC = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const handleThumbnailClick = () => {
    setIsVideoVisible(true);
  };

  return (
    <section id="mehanizam" className="mechanism-section py-5 bg-primary text-white">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="font-upercase">U OLIMPIJSKIM IGRAMA UŽIVAJ, NAGRADE OSVAJAJ</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="text-center mt5 mb-lg-0 d-flex flex-column align-items-center">
            <img src="../rosa-flasice-levo3.png" alt="Rosa Bottles" className="img-fluid mt5 mb-1" />
            <p className="text-promo">
              U promociji učestvuju pakovanja Rosa nagazirana 0,5L PET i 0,75L PET. <br />
              Promocija traje od 22.07. do 2.9.2024. godine.
            </p>
          </Col>
          <Col lg={6}>
            <div className="mb-4 d-flex align-items-center">
              {/* <img src="../flasi-desno.png" alt="Icon 1" className="mr-3" /> */}
              <p className='custom-text'>
                  Nagradna igra je završena!
                  <br />
                  <br />
                  Ukoliko imate dodatnih pitanja
                  <br />
                  <br />
                  kontaktirajte nas putem 
                  <br />
                  <br />
                  e-mail adrese:
                  <br />
                  <br />
                  <a
                    href="mailto:info@prvikorakdonagrada.rs"
                    className="line"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    info@prvikorakdonagrada.rs
                    <br />
                  </a></p>
            </div>
            {/* <div className="mb-4 d-flex align-items-center">
              <img src="./rsz_korak2.png" alt="Icon 2" className="mr-3" />
              <p className='custom-text'>Pošalji PFR broj na 6202 <br className="d-none d-md-block"/> u formi ROSA (razmak) PFR BROJ</p>
            </div> */}
            {/* <div className="mb-4 d-flex align-items-center">
              <img src="./korak_3_v2.png" alt="Icon 3" className="mr-3" />
              <p className='custom-text'>Osvoji Nagrade</p>
            </div> */}
            <div className="text-center position-relative">
              {!isVideoVisible ? (
                <div className="video-thumbnail" onClick={handleThumbnailClick} style={{ position: 'relative', marginTop: '4.5%' }}>
                  <img 
                    src="../cover-video.jpg" 
                    alt="Video Thumbnail" 
                    className="img-fluid"
                  />
                  <div className="play-button position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <img src="../play-button.png" alt="Play Button" />
                  </div>
                </div>
              ) : (
                <iframe
                  title="Rosa Promotion Video"
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/9fJtyuu-vp8?start=0"
                  style={{ border: 0 }}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
            <p className="mt-1 text-video">Zvanično piće olimpijskog tima Srbije</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MechanismSection;
