import React from 'react';
import NavigationBar from './components/NavigationBar';
import MechanismSection from './components/MechanismSection';
// import PrizesSection from './components/PrizesSection';
// import RulesSection from './components/RulesSection';
import './App.scss';
import PrizesSection from './components/PrizesSection';
import LineBackgroundSection from './components/LineBackgroundSection';
import WinnersSection from './components/WinnersSection';
import RulesAndContact from './components/RulesAndContact';

const App: React.FC = () => {
  return (
    <div className="App">
      <NavigationBar />
      <MechanismSection />
      <LineBackgroundSection />
      <PrizesSection />
      <LineBackgroundSection />
      <WinnersSection />
      <LineBackgroundSection />
      <RulesAndContact />
      <LineBackgroundSection />
    </div>
  );
};

export default App;
