import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../App.scss';

const RulesAndContact: React.FC = () => {
  const handleDownload = () => {
    window.open("/Rosa_UZIVAJ_U_OLIMPIJSKIM_IGRAMA_NAGRADE_OSVAJAJ_03.07.2024.pdf");
  };

  return (
    <section id="pravila-kontakt" className="rules-contact-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <p>
              Ova Pravila stupaju na snagu danom njihovog donošenja,
              a prema trećim licima danom javnog objavljivanja u dnevnom listu,
              a nakon dobijanja saglasnosti Ministra finansija.
            </p>
            <Button onClick={handleDownload} className="rules-button">
              PRAVILA
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 contact-margin">
            <Col className="contact-text">
              <span >KONTAKT</span>
            </Col>
            </Row>
        <Row className="justify-content-center">
          <Col md={6} className="text-center contact-info">
            <p>MAIL:</p>
            <p><a href="mailto:info@prvikorakdonagrada.rs" style={{ color: 'inherit', textDecoration: 'none' }}>info@prvikorakdonagrada.rs</a></p>
            <br />
            {/* <p>Broj telefona:</p>
            <p><a href="tel:062482520" style={{ color: 'inherit', textDecoration: 'none' }}>062482520</a></p> */}
          </Col>
        </Row>
        {/* <Row className="justify-content-center">
          <Col md={10} className="text-center">
            <p className='text-center-bottom'>
                U promociji učestvuju pakovanja Rosa nagazirana 0,5L PET i 0,75L PET.
                Promocija traje od 22.07. do 2.9.2024. godine. Pokloni su ilustrativnog karaktera.
            </p>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default RulesAndContact;
