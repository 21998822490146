import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.scss';
import getWinnersWeekly from '../helpers/getWinnersWeekly';

interface Winner {
  phone: string;
  award:string;
}

const WinnersSection: React.FC = () => {
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [winners, setWinners] = useState<Winner[]>([]);
  const [message, setMessage] = useState<string>("");

  const weekOptions = Array.from({ length: 6 }, (_, index) => index + 1);

  useEffect(() => {
    const fetchWinners = async () => {
      const response = await getWinnersWeekly(selectedWeek);

      if (response.length === 0) {
        setMessage("Nema dobitnika za odabrani datum");
        setWinners([]);
      } else {
        setMessage("");
        setWinners(response);
      }
    };

    fetchWinners();
  }, [selectedWeek]);

  return (
    <section id="lista-dobitnika" className="winners-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="winners-header">
              <select
                className="week-dropdown"
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(Number(e.target.value))}
              >
                {weekOptions.map((week) => (
                  <option key={week} value={week}>
                    {week}. nedelja
                  </option>
                ))}
              </select>
            </div>
            <div className="winners-table-wrapper">
              <img src="../tabela(1).png" alt="Tabela Background" className="table-background" />
              <div className="winners-table">
                {message ? (
                  <div className="no-winners-message">{message}</div>
                ) : (
                  <table className='custom-font'>
                    <tbody>
                      {winners.map((winner, index) => (
                        <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                          <td>{index + 1}.</td>
                          <td>{`${winner.phone}`}</td>
                          <td>{`${winner.award}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WinnersSection;
