import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../App.scss';

const NavigationBar: React.FC = () => {
  const handleNavItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, targetId: string) => {
    event.preventDefault();
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      let yOffset = -70; // Default offset
  
      // Specific offset for "lista dobitnika"
      if (targetId === '#lista-dobitnika') {
        if (window.innerWidth <= 991) {
          yOffset = -500; // Offset for mobile devices
        } else {
          yOffset = -150; // Offset for desktop devices
        }
      }
  
      const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  
      const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
      const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
      if (navbarToggler && navbarCollapse && navbarCollapse.classList.contains('show')) {
        navbarToggler.click();
      }
    }
  };
  
  

  const handleLogoClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.history.pushState("", document.title, window.location.pathname + window.location.search);

    removeActiveClass();
  };

  const removeActiveClass = () => {
    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach(link => {
      link.classList.remove('active');
    });
  };

  return (
    <Navbar bg="primary" variant="dark" expand="lg" sticky="top" className="custom-navbar">
      <Container>
        <Navbar.Brand href="#home" className="d-flex align-items-center" onClick={handleLogoClick}>
          <img src="../header4.png" alt="Rosa Logo" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="nav-links">
          <Nav.Link href="#mehanizam" className="nav-link" onClick={(e) => handleNavItemClick(e, '#mehanizam')}>MEHANIZAM</Nav.Link>
<Nav.Link href="#nagrade" className="nav-link" onClick={(e) => handleNavItemClick(e, '#nagrade')}>NAGRADE</Nav.Link>
<Nav.Link href="#lista-dobitnika" className="nav-link" onClick={(e) => handleNavItemClick(e, '#lista-dobitnika')}>LISTA DOBITNIKA</Nav.Link>
<Nav.Link href="#pravila-kontakt" className="nav-link" onClick={(e) => handleNavItemClick(e, '#pravila-kontakt')}>PRAVILA</Nav.Link>
<Nav.Link href="#pravila-kontakt" className="nav-link" onClick={(e) => handleNavItemClick(e, '#pravila-kontakt')}>KONTAKT</Nav.Link>

          </Nav>
          {/* <Nav.Item className="d-flex align-items-center">
            <img src="../grb.png" alt="Grb" className="navbar-logo ml-3" />
          </Nav.Item> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
