import React from 'react';
import '../App.scss';

const LineBackgroundSection: React.FC = () => {
  return (
    <div className="line-background-section"></div>
  );
};

export default LineBackgroundSection;
