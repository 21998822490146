import axios, { AxiosRequestConfig } from "axios";

const getWinnersWeekly = async (week: number): Promise<any> => {
  try {
    // date = "20210930";
    let date;
    // ovo treba izmeniti
    switch (week) {
      case 1:
        // date = "20240728";
        date = "20240728";
        break;
      case 2:
        date = "20240804";
        break;
      case 3:
        date = "20240811";
        break;
      case 4:
        date = "20240818";
        break;
      case 5:
        date = "20240825";
        break;
      case 6:
        date = "20240901";
        break;
      default:
        date = "20240728";
        break;
    }
    const config: AxiosRequestConfig = {
      method: "GET",
    //   url: `https://ultrachallenge.rs/api/ultra/winners-w`,
      // url: `https://cceurocup2024.me/api/ultra/winners-w`,
      url: `https://ultrachallenge.rs/api/ultra/rosa-winners-w`,

      headers: {
        "Content-Type": "application/json",
      },
      params: {
        date,
      },
    };

    const response = await axios(config);
    const { data } = response;
    const { code } = data;

    if (code !== 200) {
      return [];
    }

    return data.data;
  } catch (error) {
    return [];
  }
};

export default getWinnersWeekly;
