import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrizesSection: React.FC = () => {
  const prizes = [
    { id: 1, src: '../nagrada1.png', alt: 'Majica' },
    { id: 2, src: '../nagrada2.png', alt: 'Torba' },
    { id: 3, src: '../nagrada3.png', alt: 'Sportska Flaša' },
    { id: 4, src: '../nagrada4.png', alt: '251 nagrada' },
    { id: 5, src: '../nagrada5.png', alt: 'Smart Watch' },
    { id: 6, src: '../nagrada6.png', alt: 'Gift Card 100.000 din' },
    { id: 7, src: '../nagrada7.png', alt: 'Gift Card 10.000 din' },
    { id: 8, src: '../nagrada8.png', alt: 'Slušalice' },
    { id: 9, src: '../nagrada9.png', alt: 'Shaker' },
  ];

  return (
    <section id="nagrade" className="prizes-section py-5 bg-primary text-white">
      <div className="top-wave-line">
      <img src="../wave_line.png" alt="Top Wave Line"/>
      </div>
      {/* <img src="../wave_line_top.png" alt="Top Wave Line" className="top-wave-line" /> */}
      <Container>
        <Row className="align-items-center justify-content-center">
          {prizes.slice(0, 3).map((prize) => (
            <Col xs={12} sm={6} lg={2} className="text-center mb-4" key={prize.id}>
              <div className="prize">
                <img src={prize.src} alt={prize.alt} className="img-fluid prize-image" />
              </div>
            </Col>
          ))}
          <Col xs={12} sm={6} lg={4} className="text-center mb-4" key={prizes[3].id}>
            <div className="prize prize-large">
              <img src={prizes[3].src} alt={prizes[3].alt} className="img-fluid prize-image-large" />
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          {prizes.slice(4).map((prize) => (
            <Col xs={12} sm={6} lg={2} className="text-center mb-4" key={prize.id}>
              <div className="prize">
                <img src={prize.src} alt={prize.alt} className="img-fluid prize-image" />
              </div>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center">
            <p className="text-center-bottom">Pokloni su ilustrativnog karaktera.</p>
          </Col>
        </Row>
      </Container>
      <div className="bottom-wave-line">
      <img src="../wave_line_bottom2.png" alt="Bottom Wave Line"  />
      </div>
      {/* <img src="../wave_line_bottom.png" alt="Bottom Wave Line" className="bottom-wave-line" /> */}
    </section>
  );
};

export default PrizesSection;
